<template>
   <v-footer id="home-footer" color="black" dark min-height="72">
      <v-container fluid>
         <v-row>
            <v-col cols="12" md="6">
               <div v-if="$store.getters.isLoggedIn" class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
                  <template v-for="(tool, idx) in tools">
                     <!-- <a :key="idx" class="white--text pa-1 pa-md-0" :href="tool.href" v-text="tool.text" /> -->
                     <router-link v-if="shown(tool)" :key="idx" class="white--text pa-1 pa-md-0" :to="tool.link" v-text="tool.text" />
                     <v-responsive v-if="shown(tool) && idx < tools.length - 1" :key="`divider-${tool.text}`" class="mx-4 shrink hidden-sm-and-down" max-height="24">
                        <v-divider vertical />
                     </v-responsive>
                  </template>
               </div>
            </v-col>
            <v-col class="text-center text-md-right" cols="12" md="6">
               {{ `Copyright &copy; 2022 ${$store.state.setting.global.copyright}&trade; &mdash; v${version.slice(0, 3)}.${version.slice(3, 4)}.${version.slice(-1)}` }}
            </v-col>
         </v-row>
      </v-container>
   </v-footer>
</template>

<script>
import { version } from '../../../package'
export default {
   name: 'HomeFooter',

   data: () => ({
      version: version.replace(/\D/g, '').slice(-5),
      tools: [
         { text: 'Dashboard', link: '/dashboard', auth: ['dashboard'] },
         { text: 'Reports', link: '/reports', auth: ['report'] },
      ],
   }),

   computed: {
      shown () {
         return tool => Object.keys(this.$store.state.user).some(s => tool.auth.includes(s))
      },
   },
   created () {
   },
}
</script>

<style lang="sass">
   #home-footer a
      text-decoration: none
</style>
